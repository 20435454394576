import * as React from "react";
// import 'normalize.css'
import "../styles/shared.module.css";
import "../styles/layout.css";
import { Open_Sans } from "next/font/google";

const openSans = Open_Sans({
	subsets: ["latin"],
	weight: ["400", "700", "800"],
});

function App({ Component, pageProps }) {
	return (
		<main className={openSans.className}>
			<Component {...pageProps} />
		</main>
	);
}

export default App;
